import { initializeApp } from "firebase/app";
const firebaseConfig = {
  apiKey: "AIzaSyAfbqx21F1O3fzTkw_aZt-Z_7GBl7byNdo",
  authDomain: "sumit-428f6.firebaseapp.com",
  projectId: "sumit-428f6",
  storageBucket: "sumit-428f6.appspot.com",
  messagingSenderId: "776172609966",
  appId: "1:776172609966:web:451e84db32f9675f71683f"
};
export const app = initializeApp(firebaseConfig);





